import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";

// URL dinámica para el backend
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "https://chihuahuamarket.mx:5000"; // Ajustar para producción

const UPLOADS_ENDPOINT = "/uploads"; // Endpoint para obtener archivos

const ArchivosSubidos = () => {
  const [archivos, setArchivos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchArchivos = async () => {
      setLoading(true);
      setError(false);

      try {
        const response = await axios.get(`${BACKEND_URL}${UPLOADS_ENDPOINT}`);
        setArchivos(response.data);
      } catch (err) {
        console.error("Error al obtener los archivos:", err.message);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchArchivos();
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        minHeight: "calc(65vh - 60px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Autoevaluaciones
      </Typography>

      {loading && (
        <Typography variant="body1">Cargando archivos...</Typography>
      )}

      {!loading && error && (
        <Typography variant="body1" color="error">
          Ocurrió un error al cargar los archivos. Por favor, intenta nuevamente.
        </Typography>
      )}

      {!loading && !error && archivos.length === 0 && (
        <Typography variant="body1">
          No se encontraron archivos subidos.
        </Typography>
      )}

      {!loading && !error && archivos.length > 0 && (
        <TableContainer
          component={Paper}
          style={{ flex: 1, overflowY: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Nombre</strong></TableCell>
                <TableCell><strong>Archivo</strong></TableCell>
                <TableCell><strong>Enlace</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {archivos.map((archivo, index) => (
                <TableRow key={index}>
                  <TableCell>{archivo.name || "Sin nombre"}</TableCell>
                  <TableCell>{archivo.filename || "Sin archivo"}</TableCell>
                  <TableCell>
                    <a
                      href={`${BACKEND_URL}${UPLOADS_ENDPOINT}/${archivo.filename}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver archivo
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ArchivosSubidos;
