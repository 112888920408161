import "./Estilos/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";  // Solo una importación
import Inicio from "./Paginas/Inicio/Inicio";
import Footer from "./Componentes/Footer/Footer";
import QueEs from "./Paginas/QueEs/QueEs";
import Faq from "./Paginas/Faq/Faq";
import IniciarSeion from "./Auth/Auth/IniciarSesion";
import Estructura from "./Paginas/Estructura/Estructura";
import CrearCuenta from "./Auth/Auth/CrearCuenta";
import VerificarCuenta from "./Auth/Auth/VerificarCuenta";
import Auth from "./Auth/Auth/Auth";
import EstructuraPortal from "./Portal/Paginas/Estructura/Estructura";
import Landing from "./Portal/Paginas/Landing/Landing";
import FormularioRegistro from "./Portal/Paginas/FormularioRegistro/FormularioRegsitro";
import DatosFiscales from "./Portal/Paginas/FormularioRegistro/EtapasFormulario/DatosFiscales/DatosFiscales";
import DatosEmpresa from "./Portal/Paginas/FormularioRegistro/EtapasFormulario/DatosEmpresa/DatosEmpresa";
import DatosComerciales from "./Portal/Paginas/FormularioRegistro/EtapasFormulario/DatosComerciales/DatosComerciales";
import Contacto from "./Paginas/Contacto/Contacto";
import RecuperarCuenta from "./Auth/Auth/RecuperarCuenta";
import Tabla from "./Paginas/Tabla/Tabla";
import ArchivosSubidos from './Paginas/ArchivosSubidos'; // Asegúrate de importar el componente ArchivosSubidos



function App() {
  return (
    <div className="App">
      <BrowserRouter>  {/* Solo un BrowserRouter */}
        <Routes>
          <Route path="/" element={<Estructura />}>
            <Route index element={<Inicio />} />
            <Route path="/inicio" element={<h1>holi</h1>} />
            <Route path="/Que-Es" element={<QueEs />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/Contacto" element={<Contacto />} />           
            <Route path="/archivos" element={<ArchivosSubidos />} />  {/* Ruta de la página para ver archivos subidos */}
          </Route>

          <Route path="/Auth" element={<Auth />}>
            <Route index element={<IniciarSeion />} />
            <Route path="Crear-Cuenta" element={<CrearCuenta />} />
            <Route path="Verificar-Cuenta" element={<VerificarCuenta />} />
            <Route path="Recuperar-Cuenta" element={<RecuperarCuenta />} />
          </Route>

          <Route path="/Portal" element={<EstructuraPortal />}>
            <Route index element={<Landing />} />
            <Route path="Formulario" element={<FormularioRegistro />}>
              <Route index element={<DatosFiscales />} />
              <Route path="Datos-Empresa" element={<DatosEmpresa />} />
              <Route path="Datos-Comerciales" element={<DatosComerciales />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
