import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const refMenuResponsivo = useRef(0);
  const [menuResponsive, setMenuResponsive] = useState(false);

  const abrirMenu = () => {
    if (menuResponsive) {
      refMenuResponsivo.current.style.maxHeight = "0px";
      refMenuResponsivo.current.style.padding = "0px";
      setMenuResponsive(false);
    } else {
      refMenuResponsivo.current.style.maxHeight = "1000px";
      refMenuResponsivo.current.style.padding = "1rem 0px";
      setMenuResponsive(true);
    }
  };

  return (
    <>
      <nav>
        <div className="contenedor-logotipo-responsivo">
          <img
            src="/assets/logos/logoChihuahuaMarket.png"
            alt="logo-chihuahua-mkt"
          />
        </div>
        <div className="menu">
          <button onClick={() => navigate("/")}>Inicio</button>
          <button onClick={() => navigate("/Que-Es")}>
            ¡Chihuahua Market!
          </button>
          <button
            onClick={() =>
              window.open(
                "https://tienda.mercadolibre.com.mx/chihuahua-market",
                "_blank"
              )
            }
          >
            Tienda Oficial
          </button>
          <button onClick={() => navigate("/FAQ")}> FAQ</button>
          <button onClick={() => navigate("/Contacto")}>Contacto</button>
        </div>

        <div className="llamado-accion">
          <button
            onClick={() =>
              document
                .getElementById("Pasos")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Obtén tu distintivo
          </button>
        </div>

        <div className="redes-sociales">
          <FontAwesomeIcon
            icon={faFacebook}
            className="icono"
            onClick={() =>
              window.open(
                "https://www.facebook.com/ChihuahuaMarket.mx/?locale=es_LA"
              )
            }
          />
          <FontAwesomeIcon
            className="icono"
            icon={faInstagram}
            onClick={() =>
              window.open("https://www.instagram.com/chihuahuamarket/")
            }
          />
          <FontAwesomeIcon
            className="icono"
            icon={faLinkedin}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/chihuahua-market-side/"
              )
            }
          />
          <FontAwesomeIcon
            className="icono"
            onClick={() =>
              window.open("https://www.tiktok.com/@chihuahuamarket1")
            }
            icon={faTiktok}
          />
          <FontAwesomeIcon
            className="icono"
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCzxlt-L7Qcxbhr_xorH4chA"
              )
            }
            icon={faYoutube}
          />
        </div>

        <div className="contenedor-logotipo">
          <img src="/assets/logos/logoHeader.svg" alt="logo-gob" />
        </div>
        <button onClick={abrirMenu} className="menu-responsivo">
          <FontAwesomeIcon icon={faBars} />
        </button>
      </nav>
      <aside ref={refMenuResponsivo}>
        <button onClick={() => navigate("/")}>Inicio</button>
        <button onClick={() => navigate("/Que-Es")}>¡Chihuahua Market!</button>
        <button
          onClick={() =>
            window.open(
              "https://tienda.mercadolibre.com.mx/chihuahua-market",
              "_blank"
            )
          }
        >
          Tienda Oficial
        </button>
        <button onClick={() => navigate("/FAQ")}> FAQ</button>
        <button>Contacto</button>
        <button>Boletín</button>
        <button
          onClick={() =>
            document
              .getElementById("Pasos")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Obten tu distintivo
        </button>
        <div className="redes-sociales">
          <FontAwesomeIcon
            icon={faFacebook}
            onClick={() =>
              window.open(
                "https://www.facebook.com/ChihuahuaMarket.mx/?locale=es_LA"
              )
            }
          />
          <FontAwesomeIcon
            icon={faInstagram}
            onClick={() =>
              window.open("https://www.instagram.com/chihuahuamarket/")
            }
          />
          <FontAwesomeIcon
            className="icono"
            icon={faLinkedin}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/chihuahua-market-side/"
              )
            }
          />
          <FontAwesomeIcon
            className="icono"
            onClick={() =>
              window.open("https://www.tiktok.com/@chihuahuamarket1")
            }
            icon={faTiktok}
          />
          <FontAwesomeIcon
            className="icono"
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCzxlt-L7Qcxbhr_xorH4chA"
              )
            }
            icon={faYoutube}
          />
        </div>
      </aside>
    </>
  );
};

export default Header;
