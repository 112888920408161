import { Button, TextField } from "@mui/material";
import Paso from "./Paso";
import { useState } from "react";
import axios from "axios";

// Define dinámicamente la URL del backend
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "https://chihuahuamarket.mx:5000"; // Cambiar para producción

const Pasos = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const pasos = [
    { imagen: "/imgs/paso_1.webp", contenido: "Llenar formulario de registro" },
    { imagen: "/imgs/paso_3.webp", contenido: "DESCARGA autodiagnóstico" },
    { imagen: "/imgs/paso_2.webp", contenido: "Subir autodiagnóstico" },
    { imagen: "/imgs/paso_4.webp", contenido: "Proceso de Evaluación" },
    { imagen: "/imgs/paso_5.webp", contenido: "Ya eres ¡Chihuahua Market!" },
  ];

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    setName("");
    setFile(null);
  };

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleSubmit = async () => {
    if (!name || !file) {
      alert("Por favor, complete todos los campos.");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);

    setIsUploading(true);

    try {
      const response = await axios.post(`${BACKEND_URL}/uploads`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert(response.data.message || "Archivo subido con éxito.");
      closeModal();
    } catch (error) {
      console.error("Error al subir el archivo:", error);
      alert("Ocurrió un error al subir el archivo. Intente nuevamente.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="contenedor-pasos">
      <div className="pasos-titulo">
        <h1>Pasos para obtener tu distintivo</h1>
      </div>
      <div className="pasos-contenido">
        {pasos.map((paso, index) => (
          <div key={index} className="paso-contenedor">
            <Paso imagen={paso.imagen} contenido={paso.contenido} />
            {index === 0 && (
              <Button
                className="boton3"
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/1A2I1gB5d-eDbdzcgdSknK1yVAsHTIDyPpLVXUfsBfG8/viewform?chromeless=1&edit_requested=true",
                    "_blank"
                  )
                }
              >
                Llenar
              </Button>
            )}
            {index === 1 && (
              <Button
                className="boton3"
                variant="contained"
                color="primary"
                href="/docs/Cuestionario de Diagnostico.xlsx"
                download="Autodiagnostico"
              >
                Descargar
              </Button>
            )}
            {index === 2 && (
              <Button
                className="boton3"
                variant="contained"
                color="primary"
                onClick={openModal}
              >
                Subir
              </Button>
            )}
          </div>
        ))}
      </div>

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Carga tu autodiagnóstico</h2>
            <TextField
              label="Nombre"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
            />
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              style={{ margin: "10px 0" }}
            />
            <div style={{ marginTop: "20px" }}>
              <Button
                className="boton3"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isUploading}
              >
                {isUploading ? "Subiendo..." : "Enviar"}
              </Button>
              <Button
                className="boton3"
                variant="contained"
                color="secondary"
                onClick={closeModal}
                style={{ marginLeft: "10px" }}
                disabled={isUploading}
              >
                Cerrar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pasos;
